@import url("https://fonts.googleapis.com/css2?family=Plaster&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  backdrop-filter:'hue-rotate(90deg)' !important;
}

.box-container {
  padding-bottom: 20rem;
}

.box {
  width: 25rem;
  height: 25rem;
  background-color: red;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.progress {
  position: fixed;
  left: 0;
  right: 0;
  height: 5px;
  background: var(--accent);
  bottom: 100px;
}

.scroll-text {
  font-family: "Plaster", cursive !important ;
  font-style: normal;
}

.parallax {
  overflow: hidden;
  letter-spacing: -2px;
  line-height: 0.8;
  margin: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
}

.parallax .scroller {
  color: #154975;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 64px;
  display: flex;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
}



.layout-cards {
  width: 100%;
  height: 100%;
  padding: 3rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 1.5rem;
  background: #ff471a;
  border-radius: 15px;
}
.dim-layer {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background: black;
  opacity: 0;
  pointer-events: none;
}
.opened-card {
  border-radius: 15px;
  cursor: pointer;
  height: calc(100% - 10rem);
  width: 40rem;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  z-index: 10;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
}
.card-div {
  background: white;
  height: 100%;
  width: 100%;
  margin: 0 1rem;
  border-radius: 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.first-child {
  margin-left: 0;
}

.card {
  background: white;
  border-radius: 15px;
  width: 25rem;
  height: 25rem;
  cursor: pointer;
}
.nth-child-1 {
  grid-column: 1 / span 2;
  grid-row: 1;
}
.nth-child-2 {
  grid-column: 3;
  grid-row: 1;
}
.nth-child-3 {
  grid-column: 1;
  grid-row: 2;
}
.nth-child-4 {
  grid-column: 2 / span 2;
  grid-row: 2;
}

.cards-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  line-height: 33rem;
  max-width: 100%;
  white-space: nowrap;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  border-radius: 15px;
}
::-webkit-scrollbar {
    display: none;
  }

  .custom-shape-divider-top-1683822910 {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-top-1683822910 svg {
    position: relative;
    width: calc(109% + 1.3px);
    height: 70px;
}

.custom-shape-divider-top-1683822910 .shape-fill {
    fill: #FFFFFF;
}